import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import NavigationItems from "../../components/Navigations/NavigationItems";
import Aux from "../../hoc/Misc/Misc";

// custom styling
const customStyle = {
  topBuffer30: {
    marginTop: "30px",
    textAlign: "center",
  },
};

// inBuilt bootstrap style
const controlClasses = {
  frame: {
    col: {
      span: 8,
      offset: 2,
    },
  },
};

class NotFoundPage extends Component {
  state = {
    navlinks: {
      first: {
        name: "Retrieve Application",
        link: "/",
        type: "normal",
      },

      mid: {
        name: "Admin Panel",
        link: "/admin",
        type: "normal",
      },

      last: {
        name: "About the Programme",
        link: "https://aisingapore.org/research/ai-amp/",
        type: "normal",
      },
    },
  };

  render() {
    return (
      <Aux>
        <NavigationItems links={this.state.navlinks} />
        <Container>
          <Row>
            <Col md={controlClasses.frame.col} style={customStyle.topBuffer30}>
              <p>
                <b>Error 404: Oops, there is nothing to see here.</b>
              </p>
            </Col>
          </Row>
        </Container>
      </Aux>
    );
  }
}

export default NotFoundPage;
