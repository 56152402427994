import React from "react";
import { customStyle } from "./CustomStyle";

export const FormMapper = {
  // theme mapping
  theme1: "Theme One: Collaborative AI",
  theme2: "Theme Two: Trustworthy and Explainable AI",
  theme3: "Theme Three: Resource-Efficient AI",
  theme4: "Theme Four: Privacy-Aware AI",
  theme5: "Theme Five: Continuous Learning AI",

  // core tech areas
  core1: "Cognitive modelling and systems",
  core2: "Game theory and economic paradigms",
  core3: "Heuristic search and optimization",
  core4: "Knowledge representation and reasoning",
  core5: "Machine Learning",
  core6: "Multiagent Systems",
  core7: "Natural Language Processing (NLP)",
  core8: "Planning and Scheduling",
  core9: "Reasoning under uncertainty",
  core10: "Robotics",
  core11: "Search and constraint satisfaction",
  core12: "Vision",

  // institution
  nus: "National University of Singapore (NUS)",
  ntu: "Nanyang Technological University (NTU)",
  smu: "Singapore Management University (SMU)",
  suss: "Singapore University of Social Science (SUSS)",
  sutd: "Singapore University of Technology and Design (SUTD)",
  sit: "Singapore Institute of Technology (SIT)",
  "a-star": "Agency for Science, Technology and Research (A*STAR)",
  aisg: "AI Singapore",

  // department mapping
  "ai-technology": "AI Technology",
  "ai-innovation": "AI Innovation",
  "ai-research": "AI Research",
  soc: "School of Computing",
  eee: "School of Electrical and Electronic Engineering",
  scse: "School of Computer Science and Engineering",
  sis: "School of Computing and Information Systems",
  cde: "College of Design & Engineering",
  istd: "Information Systems Technology and Design",
  esd: "Engineering Systems and Design",
  mse: "School of Materials Science and Engineering",
  fos: "Faculty of Science",
  ids: "Institute of Data Science",
  duke: "Duke-NUS",
  ssh: "Saw Swee Hock School of Public Health",
  isep: "Instegrative Sciences & Engineering Programme",
  spms: "School of Physical and Mathematical Sciences",
  igp: "Interdisciplinary Graduate Programme",

  // For the new qualifying criteria.
  urop_ureca: "UROP or URECA",
  aira: "AI Research Attachment",
  aipub: "AI Publication",

  // status mapping
  unfilled: "Pending Applicant",
  filled: "Pending Admin",
  "complete-un": "Pending Endorsement",
  "complete-en": "Completed (Endorsed)",
  "pending-ass": "Pending Assessment",
  awarded: "Awarded",
  complete: "Completed",
  reject: "Rejected",
  withdrawn: "Withdrawn",

  // outcome mapping
  pending: "Pending Review",
  rejected: "Rejected",
  /* Already mapped earlier. */
  //awarded: "Awarded",
  conditional_award: "Conditional Offer",

  // user mapping
  "reviewer-final": "Final Reviewer",
  reviewer: "Reviewer",
  "admin-super": "Admin (Superuser)",
  "admin-fa": "Admin (Faculty)",
  applicant: "Applicant",

  // The different supervisor types.
  interest: "Interested Supervisor",
  intended: "Intended Supervisor",
};

export const RevMapper = {
  overall_score: {
    4: [
      <>
        4 - Excellent
        <br />
        <span style={customStyle.subText}>
          The applicant is outstanding.
          <ul>
            <li>strong track records in academic,</li>
            <li>
              obtained prestigious academic and/or international awards,{" "}
              <u>and/or</u>
            </li>
            <li>published strong AI publications as main author, etc.</li>
          </ul>
          I am in favour that he/she should be supported under the AISG AI
          Accelerated Masters Programme.
        </span>
      </>,
    ],
    3: [
      <>
        3 - Good
        <br />
        <span style={customStyle.subText}>
          The applicant is good.
          <ul>
            <li>good track records in academic,</li>
            <li>
              obtained some academic and/or international awards, <u>and/or</u>
            </li>
            <li>published AI publications as secondary author, etc.</li>
          </ul>
          I think he/she can be supported under the AISG AI Accelerated Masters
          Programme. Otherwise, he/she can be placed in the KIV list.
        </span>
      </>,
    ],
    2: [
      <>
        2 - Average
        <br />
        <span style={customStyle.subText}>
          The applicant is lacking in more than one area.
          <br />
          He/she may not be competitive enough to be considered for the AISG AI
          Accelerated Masters Programme.
        </span>
      </>,
    ],
    1: [
      <>
        1 - Poor
        <br />
        <span style={customStyle.subText}>
          The applicant is definitely not competitive enough to be considered
          for the AISG AI Accelerated Masters Programme.
        </span>
      </>,
    ],
  },
  expertise_score: {
    4: [
      <>
        4 - Excellent
        <br />
        <span style={customStyle.subText}>
          The intended supervisor(s) / current supervisor(s) has/have an
          excellent track record in fundamental AI research.
        </span>
      </>,
    ],
    3: [
      <>
        3 - Good
        <br />
        <span style={customStyle.subText}>
          The intended supervisor(s) / current supervisor(s) somewhat has/have a
          reasonable record in fundamental AI research.
        </span>
      </>,
    ],
    2: [
      <>
        2 - Average
        <br />
        <span style={customStyle.subText}>
          I have some reservations on the intended supervisor(s) / current
          supervisor(s) as he/she may not have the necessary expertise in
          fundamental AI research to be able to advise the applicant.
        </span>
      </>,
    ],
    1: [
      <>
        1 - Poor
        <br />
        <span style={customStyle.subText}>
          The intended supervisor(s) / current supervisor(s) does/do not have
          the expertise in fundamental AI research.
        </span>
      </>,
    ],
  },
  suitability_score: {
    4: [
      <>
        4 - Excellent
        <br />
        <span style={customStyle.subText}>
          The intended supervisor(s) / current supervisor(s) is / are a close
          match to the applicant's research interests/directions in AI.
        </span>
      </>,
    ],
    3: [
      <>
        3 - Good
        <br />
        <span style={customStyle.subText}>
          The intended supervisor(s) / current supervisor(s) can provide general
          advice and guidance regarding the applicant's research
          interests/directions in AI.
        </span>
      </>,
    ],
    2: [
      <>
        2 - Average
        <br />
        <span style={customStyle.subText}>
          I have some reservations on the intended supervisor(s) / current
          supervisor(s) in advising the applicant's research
          interests/directions in AI.
        </span>
      </>,
    ],
    1: [
      <>
        1 - Poor
        <br />
        <span style={customStyle.subText}>
          The intended supervisor(s) / current supervisor(s) is / are a poor
          match to the applicant's research interests/directions in AI and hence
          cannot advise him / her well.
        </span>
      </>,
    ],
  },
  direction_score: {
    4: [
      <>
        4 - Excellent
        <br />
        <span style={customStyle.subText}>
          A clear articulation of the applicant's intended research directions
          in AI with great vision and depth.
        </span>
      </>,
    ],
    3: [
      <>
        3 - Good
        <br />
        <span style={customStyle.subText}>
          A sufficient articulation of the applicant's intended research
          directions in AI with some vision and depth.
        </span>
      </>,
    ],
    2: [
      <>
        2 - Average
        <br />
        <span style={customStyle.subText}>
          A somewhat clear articulation of the applicant's intended research
          directions in AI that may lack vision and depth.
        </span>
      </>,
    ],
    1: [
      <>
        1 - Poor
        <br />
        <span style={customStyle.subText}>
          The applicant's proposed research directions in AI are unclear.
        </span>
      </>,
    ],
  },
  tech_area_score: {
    4: "4 - Very relevant to the AI Core Technical Areas.",
    3: "3 - Relevant to the AI Core Technical Areas.",
    2: "2 - Somewhat relevant to the AI Core Technical Areas, but too domain-specific.",
    1: "1 - Not relevant to the AI Core Technical Areas at all.",
  },
  research_focus_score: {
    4: "4 - Very relevant to One of the Five Research Focus Themes.",
    3: "3 - Relevant to One of the Five Research Focus Themes.",
    2: "2 - Somewhat relevant to One of the Five Research Focus Themes, but too domain specific.",
    1: "1 - Not relevant to any One of the Five Research Focus Themes at all.",
  },
  support_score: {
    1: "Strong Accept",
    2: "Weak Accept",
    3: "Weak Reject",
    4: "Strong Reject",
  },
};

// ********************************** //
// ****** Candidate Creation ******* //
// ********************************* //

export const app_init_admin = {
  // SECTION 1 - START
  email: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "enter candidate's email (max 200 characters)",
    },
    label: {
      main: "Section 1: Applicant Email",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  firstname: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "enter candidate's first name (max 200 characters)",
    },
    label: {
      main: "Section 1.1.1: First Name",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  lastname: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "enter candidate's last name (max 200 characters)",
    },
    label: {
      main: "Section 1.1.2: Last Name",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  undergrad_degree_name: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "enter candidate's degree name (max 200 characters)",
    },
    label: {
      main: "Section 1.2: Name of Undergraduate Degree",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  undergrad_admission_intake: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "(enter in this format [Month Year] e.g Jan 2019)",
    },
    label: {
      main: "Section 1.3: Undergrad Admission Intake",
      sub: "Month of admission into the undergraduate programme within the respective autonomous universities.",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 100,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  undergrad_expected_graduation: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "(enter in this format [Month Year] e.g Jan 2019)",
    },
    label: {
      main: "Section 1.4: Undergraduate Expected Graduation",
      sub: "Month of graduation from the undergraduate programme within the respective autonomous universities.",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 100,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  masters_expected_admission: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "(enter in this format [Month Year] e.g Jan 2019)",
    },
    label: {
      main: "Section 1.5: Masters Expected Admission",
      sub: "Expected month of admission into the AI-related Masters (Research) Programme within the respective autonomous universities.",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  admission_comment: {
    elementType: "input-textarea",
    elementConfig: {
      type: "text",
      rows: 18,
      placeholder:
        '(50 words up to max of 1,200 characters)  e.g. i) Double Degree with an expected graduation on "Month 202X" or ii) Early Graduation with an expected graduation on "Month 202X" or iii) Other Unique Scenario (Reason) with an expected graduation on "Month 202X"',
    },
    label: {
      main: "Section 1.6: Admission Comments",
      sub: "Limit of 50 words and a maximum of 1,200 characters",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxWordsLength: 50,
      maxLength: 1200,
      required: true,
    },
    value: "",
    valid: true,
    touched: false,
  },

  urop_qualifying_criteria: {
    elementType: "input-select",
    elementConfig: {
      options: [
        {
          value: "none",
          label: "<------ Please select applicant type from drop-down ------>",
        },
        { value: "urop_ureca", label: "UROP or URECA" },
        { value: "aira", label: "AI Research Attachment" },
        { value: "aipub", label: "AI Publication" },
      ],
    },
    label: {
      main: "Section 1.7: AI Research Experience Qualifying Criteria",
      sub: '"UROP or URECA" refers to Undergraduate Research Opportunities Programme (UROP), or Undergraduate Research Experience on Campus (URECA), or equivalent undergraduate research programme.',
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
  // SECTION 1 - END
};

export const user_init_admin = {
  firstname: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "enter user's first name (max 200 characters)",
    },
    label: {
      main: "First Name",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  lastname: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "enter user's last name (max 200 characters)",
    },
    label: {
      main: "Last Name",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  email: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "enter user's email (max 200 characters)",
    },
    label: {
      main: "User Email",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  user_type: {
    elementType: "input-select",
    elementConfig: {
      options: [
        {
          value: "none",
          label: "<------ Please select a theme from drop-down ------>",
        },
        { value: "admin-fa", label: "Faculty Administrator" },
        { value: "reviewer", label: "Reviewer" },
        { value: "reviewer-final", label: "Final Reviewer" },
        { value: "admin-super", label: "Super Administrator" },
      ],
    },
    label: {
      main: "User Type",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  affiliation: {
    elementType: "input-select",
    elementConfig: {
      options: [
        {
          value: "none",
          label: "<------ Please select a theme from drop-down ------>",
        },
        { value: "nus", label: "National University of Singapore (NUS)" },
        { value: "ntu", label: "Nanyang Technological University (NTU)" },
        { value: "smu", label: "Singapore Management University (SMU)" },
        {
          value: "suss",
          label: "Singapore University of Social Science (SUSS)",
        },
        {
          value: "sutd",
          label: "Singapore University of Technology and Design (SUTD)",
        },
        { value: "sit", label: "Singapore Institute of Technology (SIT)" },
        {
          value: "a-star",
          label: "Agency for Science, Technology and Research (A*STAR)",
        },
        { value: "aisg", label: "AI Singapore" },
      ],
    },
    label: {
      main: "Host Institution",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: true,
    touched: false,
  },

  department: {
    elementType: "input-select",
    elementConfig: {
      options: [
        {
          value: "none",
          label: "<------ Please select a theme from drop-down ------>",
        },
        { value: "ai-technology", label: "AI Technology" },
        { value: "ai-innovation", label: "AI Innovation" },
        { value: "ai-research", label: "AI Research" },
        { value: "soc", label: "School of Computing" },
        {
          value: "eee",
          label: "School of Electrical and Electronic Engineering",
        },
        { value: "scse", label: "School of Computer Science and Engineering" },
        { value: "sis", label: "School of Computing and Information Systems" },
        { value: "cde", label: "College of Design & Engineering" },
        { value: "istd", label: "Information Systems Technology and Design" },
        { value: "esd", label: "Engineering Systems and Design" },
        { value: "fos", label: "Faculty of Science" },
        { value: "ids", label: "Institute of Data Science" },
        { value: "duke", label: "Duke-NUS" },
        { value: "ssh", label: "Saw Swee Hock School of Public Health" },
        {
          value: "isep",
          label: "Integrative Sciences & Engineering Programme",
        },
        {
          value: "mse",
          label: "School of Materials Science and Engineering",
        },
        {
          value: "spms",
          label: "School of Physical and Mathematical Sciences",
        },
        { value: "igp", label: "Interdisciplinary Graduate Programme" },
      ],
    },
    label: {
      main: "Faculty",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: true,
    touched: false,
  },
};

export const review_init = {
  firstname: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
    },
    label: {
      main: "First Name",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  lastname: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
    },
    label: {
      main: "Last Name",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  app_id: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
    },
    label: {
      main: "Applicant Id",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  urop_qualifying_criteria: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
    },
    label: {
      main: "AI Research Experience Qualifying Criteria",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  masters_expected_admission: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
    },
    label: {
      main: "Masters Expected Admission",
      sub: "Expected month of admission into the AI-related Masters (Research) Programme within the respective autonomous universities.",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  reviewer: {
    elementType: "input-select",
    elementConfig: {},
    label: {
      main: "Reviewer",
      sub: "Please choose one of reviewers below",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
};

export const app_review_admin = {
  firstname: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
    },
    label: {
      main: "First Name",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  lastname: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
    },
    label: {
      main: "Last Name",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  urop_qualifying_criteria: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
    },
    label: {
      main: "AI Research Experience Qualifying Criteria",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  masters_expected_admission: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "[Month Year] e.g Jan 2019)",
    },
    label: {
      main: "Masters Expected Admission",
      sub: "Expected month of admission into the AI-related Masters (Research) Programme within the respective autonomous universities.",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  host_institution: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
    },
    label: {
      main: "Host Institution",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  host_faculty: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
    },
    label: {
      main: "Faculty",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  rank: {
    elementType: "input-normal",
    elementConfig: {
      type: "number",
    },
    label: {
      main: "Rank of Candidate",
      sub: "(Rank out of number of candidate either usual candidate or unique candidate)",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isNumber: true,
      maxLength: 3,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  overall_score: {
    elementType: "input-radio",
    elementConfig: {
      options: [
        {
          value: "4",
          label: [
            <>
              4 - Excellent
              <br />
              <span style={customStyle.subText}>
                The applicant is outstanding.
                <ul>
                  <li>strong track records in academic,</li>
                  <li>
                    obtained prestigious academic and/or international awards,{" "}
                    <u>and/or</u>
                  </li>
                  <li>published strong AI publications as main author, etc.</li>
                </ul>
                I am in favour that he/she should be supported under the AISG AI
                Accelerated Masters Programme.
              </span>
            </>,
          ],
        },
        {
          value: "3",
          label: [
            <>
              3 - Good
              <br />
              <span style={customStyle.subText}>
                The applicant is good.
                <ul>
                  <li>good track records in academic,</li>
                  <li>
                    obtained some academic and/or international awards,{" "}
                    <u>and/or</u>
                  </li>
                  <li>published AI publications as secondary author, etc.</li>
                </ul>
                I think he/she can be supported under the AISG AI Accelerated
                Masters Programme. Otherwise, he/she can be placed in the KIV
                list.
              </span>
            </>,
          ],
        },
        {
          value: "2",
          label: [
            <>
              2 - Average
              <br />
              <span style={customStyle.subText}>
                The applicant is lacking in more than one area.
                <br />
                He/she may not be competitive enough to be considered for the
                AISG AI Accelerated Masters Programme.
              </span>
            </>,
          ],
        },
        {
          value: "1",
          label: [
            <>
              1 - Poor
              <br />
              <span style={customStyle.subText}>
                The applicant is definitely not competitive enough to be
                considered for the AISG AI Accelerated Masters Programme.
              </span>
            </>,
          ],
        },
      ],
    },
    label: {
      main: "Section A.1. Scoring / Recommendation based on Merits of Applicant ",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  expertise_score: {
    elementType: "input-radio",
    elementConfig: {
      options: [
        {
          value: "4",
          label: [
            <>
              4 - Excellent
              <br />
              <span style={customStyle.subText}>
                The intended supervisor(s) / current supervisor(s) has/have an
                excellent track record in fundamental AI research.
              </span>
            </>,
          ],
        },
        {
          value: "3",
          label: [
            <>
              3 - Good
              <br />
              <span style={customStyle.subText}>
                The intended supervisor(s) / current supervisor(s) somewhat
                has/have a reasonable record in fundamental AI research.
              </span>
            </>,
          ],
        },
        {
          value: "2",
          label: [
            <>
              2 - Average
              <br />
              <span style={customStyle.subText}>
                I have some reservations on the intended supervisor(s) / current
                supervisor(s) as he/she may not have the necessary expertise in
                fundamental AI research to be able to advise the applicant.
              </span>
            </>,
          ],
        },
        {
          value: "1",
          label: [
            <>
              1 - Poor
              <br />
              <span style={customStyle.subText}>
                The intended supervisor(s) / current supervisor(s) does/do not
                have the expertise in fundamental AI research.
              </span>
            </>,
          ],
        },
      ],
    },
    label: {
      main: "Section A.2. AI Expertise of Intended Supervisor(s) / Current Supervisor(s)",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  suitability_score: {
    elementType: "input-radio",
    elementConfig: {
      options: [
        {
          value: "4",
          label: [
            <>
              4 - Excellent
              <br />
              <span style={customStyle.subText}>
                The intended supervisor(s) / current supervisor(s) is / are a
                close match to the applicant's research interests/directions in
                AI.
              </span>
            </>,
          ],
        },
        {
          value: "3",
          label: [
            <>
              3 - Good
              <br />
              <span style={customStyle.subText}>
                The intended supervisor(s) / current supervisor(s) can provide
                general advice and guidance regarding the applicant's research
                interests/directions in AI.
              </span>
            </>,
          ],
        },
        {
          value: "2",
          label: [
            <>
              2 - Average
              <br />
              <span style={customStyle.subText}>
                I have some reservations on the intended supervisor(s) / current
                supervisor(s) in advising the applicant's research
                interests/directions in AI.
              </span>
            </>,
          ],
        },
        {
          value: "1",
          label: [
            <>
              1 - Poor
              <br />
              <span style={customStyle.subText}>
                The intended supervisor(s) / current supervisor(s) is / are a
                poor match to the applicant's research interests/directions in
                AI and hence cannot advise him / her well.
              </span>
            </>,
          ],
        },
      ],
    },
    label: {
      main: "Section A.3. Suitability of Intended Supervisor(s) / Current Supervisor(s)",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  direction_score: {
    elementType: "input-radio",
    elementConfig: {
      options: [
        {
          value: "4",
          label: [
            <>
              4 - Excellent
              <br />
              <span style={customStyle.subText}>
                A clear articulation of the applicant's intended research
                directions in AI with great vision and depth.
              </span>
            </>,
          ],
        },
        {
          value: "3",
          label: [
            <>
              3 - Good
              <br />
              <span style={customStyle.subText}>
                A sufficient articulation of the applicant's intended research
                directions in AI with some vision and depth.
              </span>
            </>,
          ],
        },
        {
          value: "2",
          label: [
            <>
              2 - Average
              <br />
              <span style={customStyle.subText}>
                A somewhat clear articulation of the applicant's intended
                research directions in AI that may lack vision and depth.
              </span>
            </>,
          ],
        },
        {
          value: "1",
          label: [
            <>
              1 - Poor
              <br />
              <span style={customStyle.subText}>
                The applicant's proposed research directions in AI are unclear.
              </span>
            </>,
          ],
        },
      ],
    },
    label: {
      main: "Section A.4. Proposed Research Directions",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  tech_area_score: {
    elementType: "input-radio",
    elementConfig: {
      options: [
        {
          value: "4",
          label: "4 - Very relevant to the AI Core Technical Areas.",
        },
        { value: "3", label: "3 - Relevant to the AI Core Technical Areas." },
        {
          value: "2",
          label:
            "2 - Somewhat relevant to the AI Core Technical Areas, but too domain-specific.",
        },
        {
          value: "1",
          label: "1 - Not relevant to the AI Core Technical Areas at all.",
        },
      ],
    },
    label: {
      main: "Section A.5. Relevance to AI Core Technical Areas",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  research_focus_score: {
    elementType: "input-radio",
    elementConfig: {
      options: [
        {
          value: "4",
          label: "4 - Very relevant to One of the Five Research Focus Themes.",
        },
        {
          value: "3",
          label: "3 - Relevant to One of the Five Research Focus Themes.",
        },
        {
          value: "2",
          label:
            "2 - Somewhat relevant to One of the Five Research Focus Themes, but too domain specific.",
        },
        {
          value: "1",
          label:
            "1 - Not relevant to any One of the Five Research Focus Themes at all.",
        },
      ],
    },
    label: {
      main: "Section A.6. Relevance to One of the Five Research Focus Themes",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  support_score: {
    elementType: "input-radio",
    elementConfig: {
      options: [
        { value: "1", label: "Strong Accept" },
        { value: "2", label: "Weak Accept" },
        { value: "3", label: "Weak Reject" },
        { value: "4", label: "Strong Reject" },
      ],
    },
    label: {
      main: "Section B.1. Basis/Justification for your recommendation",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  support_required_comment: {
    elementType: "input-textbox",
    elementConfig: {
      type: "text",
      placeholder:
        "(Please provide feedback on the strengths and weaknesses of the applicant as well as justification for why the applicant should be accepted / rejected based on your assessment in Section A)",
    },
    label: {
      main: "Support Required Comments",
    },

    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
};

// ********************************* //
// ****** Applicant Form View ****** //
// ********************************* //
// Note that order matters (there's no indexing for re-ordering(.
export const app_form_applicant = {
  // SECTION 2 - START
  themes: {
    elementType: "input-select",
    elementConfig: {
      options: [
        {
          value: "",
          label: "<------ Please select a theme from drop-down ------>",
        },
        {
          value: "theme1",
          label: "Theme One: AI for Collaborative Decision Making",
        },
        { value: "theme2", label: "Theme Two: Trustworthy and Explainable AI" },
        { value: "theme3", label: "Theme Three: Resource-Efficient AI" },
        { value: "theme4", label: "Theme Four: Privacy-Aware AI" },
        { value: "theme5", label: "Theme Five: Continuous Learning AI" },
        { value: "others", label: "Others" },
      ],
    },
    label: {
      main: "Section 2.1.1: Theme(s)",
      sub: "(select a theme from drop-down)",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  other_theme: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: '(only if you selected "others" in the previous section)',
    },
    label: {
      main: "Section 2.1.2: Other Theme",
      sub: "(Please only fill this up if you selected Others in the previous section)",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: false,
    },
    value: "",
    valid: true,
    touched: false,
  },

  sub_themes: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder:
        "(delimit your entries with comma, e.g topic1, topic2, topic3)",
    },
    label: {
      main: "Section 2.2: Sub-Topics(s)",
      sub: "(describe the sub-topic(s) of the main theme with THREE keywords)",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  core_tech_areas: {
    elementType: "input-checkboxes",
    elementConfig: {
      choices: [
        { value: "core1", label: "Cognitive modelling and systems" },
        { value: "core2", label: "Game theory and economic paradigms" },
        { value: "core3", label: "Heuristic search and optimization" },
        { value: "core4", label: "Knowledge representation and reasoning" },
        { value: "core5", label: "Machine learning" },
        { value: "core6", label: "Multiagent systems" },
        { value: "core7", label: "Natural language processing (NLP)" },
        { value: "core8", label: "Planning and scheduling" },
        { value: "core9", label: "Reasoning under uncertainty" },
        { value: "core10", label: "Robotics" },
        { value: "core11", label: "Search and constraint satisfaction" },
        { value: "core12", label: "Vision" },
      ],
    },
    label: {
      main: "Section 2.3: Classification of AI Core Technical Areas of the Proposal",
      sub: "(Please only select up to three choices)",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      maxChoices: 3,
      minChoices: 1,
      required: true,
    },
    value: [],
    valid: false,
    touched: false,
  },
  // SECTION 2 - END
  //
  // SECTION 3 - START
  app_supervisors: {
    elementType: "input-table",
    elementConfig: {
      header: {
        firstname: {
          label: "First Name",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "Given Name",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        lastname: {
          label: "Last Name",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "Family Name",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        affiliation: {
          label: "Affiliation",
          sublabel: "",
          config: {
            type: "select",
            options: [
              {
                value: "",
                label:
                  "<------ Please select an institution from drop-down ------>",
              },
              { value: "nus", label: "National University of Singapore (NUS)" },
              { value: "ntu", label: "Nanyang Technological University (NTU)" },
              {
                value: "suss",
                label: "Singapore University of Social Science (SUSS)",
              },
              {
                value: "sutd",
                label: "Singapore University of Technology and Design (SUTD)",
              },
              {
                value: "sit",
                label: "Singapore Institute of Technology (SIT)",
              },
              { value: "smu", label: "Singapore Management University (SMU)" },
              {
                value: "a-star",
                label: "Agency for Science, Technology and Research (A*STAR)",
              },
            ],
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        faculty: {
          label: "Faculty",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "(max 200 characters)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        area_of_interest: {
          label: "Area of Interest",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        relationship: {
          label: "Type of Relation",
          sublabel: "",
          config: {
            type: "select",
            options: [
              {
                value: "",
                label:
                  "<------ Please select type of relation from drop-down ------>",
              },
              { value: "intended", label: "Intended Supervisor" },
              { value: "interest", label: "Interested Supervisor" },
            ],
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
      },
      content: [],
    },
    label: {
      main: "Section 3: List of Supervisors",
      sub: [
        <>
          <p>
            "Intended Supervisor" refers to <u>potential supervisor agreeing</u>{" "}
            to be your appointed supervisor if application is accepted by the
            university.
          </p>
          <p>
            "Interested Supervisor" refers to{" "}
            <u>potential supervisor in consideration</u> or{" "}
            <u>potential super visor who has not yet agreed</u> at the point of
            application.
          </p>
        </>,
      ],
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      // FIXME Needs at least one.
      required: true,
    },
    value: [],
    valid: true,
    touched: false,
  },
  // SECTION 3 - END
  //
  // SECTION 4 - START
  urop_qualifying_criteria: {
    elementType: "input-select",
    elementConfig: {
      options: [
        {
          value: "none",
          label: "<------ Please select applicant type from drop-down ------>",
        },
        { value: "urop_ureca", label: "UROP or URECA" },
        { value: "aira", label: "AI Research Attachment" },
        { value: "aipub", label: "AI Publication" },
      ],
    },
    label: {
      main: "Section 4.1: AI Research Experience Qualifying Criteria",
      sub: '"UROP or URECA" refers to Undergraduate Research Opportunities Programme (UROP), or Undergraduate Research Experience on Campus (URECA), or equivalent undergraduate research programme.',
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
  urop_status: {
    elementType: "input-select",
    elementConfig: {
      options: [
        {
          value: "none",
          label: "<------ Please select status from drop-down ------>",
        },
        { value: "completed", label: "Yes, Completed" },
        { value: "completing", label: "Yes, Completing" },
      ],
    },
    label: {
      main: "Section 4.2: Status",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  urop_start_date: {
    elementType: "input-normal",
    elementConfig: {
      type: "date",
    },
    label: {
      main: "Section 4.3: Start Date of the Research Experience",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  urop_expected_end_date: {
    elementType: "input-normal",
    elementConfig: {
      type: "date",
    },
    label: {
      main: "Section 4.4: (Expected) Completion Date of the Research Experience",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  urop_project_name: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "(max 200 characters)",
    },
    label: {
      main: "Section 4.5: Name of Project",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: true,
    touched: false,
  },

  urop_supervisor: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "(max 200 characters)",
    },
    label: {
      main: "Section 4.6: Name of Supervisor",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: true,
    touched: false,
  },

  urop_faculty: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "(max 200 characters)",
    },
    label: {
      main: "Section 4.7: Faculty and Institution",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: false,
    },
    value: "",
    valid: true,
    touched: false,
  },

  urop_ai_topic_domain: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      placeholder: "(three keywords and up to max of 200 characters)",
    },
    label: {
      main: "Section 4.8: AI Topic Domain",
      sub: "Describe the topic with THREE keywords",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxLength: 200,
      required: true,
    },
    value: "",
    valid: true,
    touched: false,
  },

  urop_brief_description: {
    elementType: "input-textarea",
    elementConfig: {
      type: "text",
      rows: 18,
      placeholder: "(200 words up to max of 5,000 characters)",
    },
    label: {
      main: "Section 4.9: Brief Desciption",
      sub: "Limit of 200 words and a maximum of 5,000 characters",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isText: true,
      maxWordsLength: 200,
      maxLength: 5000,
      required: true,
    },
    value: "",
    valid: true,
    touched: false,
  },

  section_4: {
    elementType: "upload",
    label: "Section 4.10: Research Experience Supplementary Document",
    sublabel: "[max 16mb] (only accept .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: false,
    validation: {
      required: true,
    },
  },
  // SECTION 4 - END
  //
  // SECTION 5 - START
  personal_statement: {
    elementType: "upload",
    label: "Section 5: Personal Statement",
    sublabel: "[max 16mb] (only accept .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: false,
    validation: {
      required: true,
    },
  },
  // SECTION 5 - END
  //
  // ANNEX A - START
  annex_a: {
    elementType: "upload",
    label: "Annex A: Curriculum Vitae",
    sublabel: "[max 16mb] (only accept .pdf format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: false,
    validation: {
      required: true,
    },
  },
  // ANNEX A - END
  //
  // ANNEX B - START
  app_qualifications: {
    elementType: "input-table",
    elementConfig: {
      header: {
        type_of_degree: {
          label: "Type of Degree",
          sublabel: "",
          config: {
            type: "select",
            options: [
              {
                value: "",
                label:
                  "<------ Please select degree type from drop-down ------>",
              },
              { value: "Diploma", label: "Diploma" },
              { value: "A Levels", label: "A Levels" },
              { value: "IB Diploma", label: "IB Diploma" },
              { value: "Undergraduate", label: "Undergraduate" },
            ],
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 50,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        name_of_degree: {
          label: "Name of Degree",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "(max 200 characters)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        awarding_institution: {
          label: "Awarding Institution",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "(max 200 characters)",
            required: true,
          },
          value: "",
          valid: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        country: {
          label: "Awarding Institution (Country)",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "(max 200 characters)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        cumu_gpa_scored: {
          label: "Cumulative GPA",
          sublabel: [
            <>
              <p>
                If numeric, do not round, and keep full decimal precision (e.g.
                1.00 and not 1).
              </p>
              <p>
                If letter grades, list down all letter-grades in capital in
                descending order (e.g. AABB, or BCCD).
              </p>
            </>,
          ],
          config: {
            type: "text",
            placeholder:
              "(numeric: do not round; letters in all capitals and descending order)",
            required: true,
          },
          value: "0",
          valid: false,
          touched: false,
          validation: {
            isText: true,
            maxLength: 10,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        cumu_gpa_max: {
          label: "Maximum GPA Score Possible",
          sublabel: [
            <>
              <p>
                If numeric, do not round, and keep full decimal precision (e.g.
                4.00, or 10.00).
              </p>
              <p>
                If letter grades, use the letter representing the highest
                available grade (e.g. A, or S).
              </p>
            </>,
          ],
          config: {
            type: "text",
            placeholder: "(numeric: do not round; letter in capital)",
            required: true,
          },
          value: "0",
          valid: false,
          touched: false,
          validation: {
            isText: true,
            maxLength: 10,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        year_conferred: {
          label: "Year of Degree Conferment",
          sublabel: "",
          config: {
            type: "number",
            placeholder: "",
            required: true,
          },
          value: "" + new Date().getFullYear(),
          valid: true,
          touched: false,
          validation: {
            isNumber: true,
            maxLength: 4,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
      },
      content: [],
    },
    label: {
      main: "Annex B: Formal Education Qualifications",
      sub: "Fill in this section for each academic qualification (with English Translation) available.",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      // FIXME Needs at least one.
      required: true,
    },
    value: [],
    valid: true,
    touched: false,
  },

  annex_b: {
    elementType: "upload",
    label: "Annex B: Education Certificates",
    //sublabel: "[max 16mb] (only accept .zip format)",
    sublabel: [
      <>
        <p>[max 16mb] (only accept .zip format)</p>
        <p>
          <b>All applicants:</b>
          <ul>
            <li>
              Certified true copy of degree certificates
              <ul>
                <li>Diploma (Polytechnic) / GCE A levels (Junior College)</li>
                <li>Bachelor's (if any)</li>
              </ul>
            </li>
            <li>
              Certified official transcripts copy
              <ul>
                <li>Polytechnic / Junior College</li>
                <li>Bachelor's (Current and Latest)</li>
              </ul>
            </li>
          </ul>
        </p>
      </>,
    ],
    feedback: "",
    value: "",
    valid: false,
    touched: false,
    loaded: false,
    validation: {
      required: true,
    },
  },
  // ANNEX B - END
  //
  // ANNEX C - START
  app_publications: {
    elementType: "input-table",
    elementConfig: {
      header: {
        publication: {
          label: "Publication Name",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "Collaborative Machine Learning",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        authors: {
          label: "Authors",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "John Doe, Jane Doe (co-first author), Patrick Doe",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        conferences: {
          label: "Conference/ Journal",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "NIPS Proceedings",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        year: {
          label: "Year of Publication",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "2020",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        link: {
          label: "Link(if any)",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "",
            required: false,
          },
          value: "",
          valid: true,
          touched: false,
          validation: {
            maxLength: 200,
            required: false,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
      },
      content: [],
    },
    label: {
      main: "Annex C.1: Publications",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: [],
    valid: true,
    touched: false,
  },

  app_awards: {
    elementType: "input-table",
    elementConfig: {
      header: {
        award: {
          label: "Awards",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "First Prize Diamond AI Award (Global)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        team: {
          label: "Individual / Team",
          sublabel: "",
          config: {
            type: "select",
            options: [
              {
                value: "",
                label:
                  "<------ Please select type of participation from drop-down ------>",
              },
              { value: "individual", label: "Individual" },
              { value: "team", label: "Team" },
            ],
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        venue: {
          label: "Venue / Scope",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "Govt Scholarship (Top 1% nationwide)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        year: {
          label: "Year of Award",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "2020",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        link: {
          label: "Link(if any)",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "",
            required: false,
          },
          value: "",
          valid: true,
          touched: false,
          validation: {
            maxLength: 200,
            required: false,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
      },
      content: [],
    },
    label: {
      main: "Annex C.2: Academic / Technical Awards",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: [],
    valid: true,
    touched: false,
  },
  annex_c: {
    elementType: "upload",
    label: "Annex C.3: Upload of documents as indicated by annexes C.1 and C.2",
    sublabel: "[max 16mb] (only accept .zip format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: false,
    validation: {
      required: false,
    },
  },
  // ANNEX C - END
  //
  // ANNEX D - START
  annex_d: {
    elementType: "upload",
    label: "Annex D: Recommendation Letters",
    sublabel: "[max 16mb] (only accept .zip format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: false,
    validation: {
      required: false,
    },
  },
  // ANNEX D - END
};

// ********************************* //
// ****** Admin Form View ****** //
// ********************************* //
// This screen should show up under the "Edit" option for FA.
export const app_form_admin = {
  // Admin Only

  // Section 1 content, pull from app_init_admin
  ...app_init_admin,

  // Section 2, 3, 4, 5, Annexes A, B, C, D content, pull from app_form_applicant
  ...app_form_applicant,

  // SECTION 6 - START
  app_other_fundings: {
    elementType: "input-table",
    elementConfig: {
      header: {
        coverage: {
          label: "Funding Coverage",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "(please indicate the inclusion of funding)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        organisation: {
          label: "Funding Organisation",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "(max 200 characters)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        duration_of_support: {
          label: "Duration of Support (No. of Years)",
          sublabel: "",
          config: {
            type: "number",
            placeholder: "(No. of Years)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            isNumber: true,
            maxLength: 10,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        awarded_date: {
          label: "Awarded Date",
          sublabel: "",
          config: {
            type: "date",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        amount: {
          label: "Funding Amount ($S)",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "(numerical only)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 10,
            isNumber: true,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        transfer: {
          label: "Allowed for Transfer of Scholarship?",
          sublabel: "",
          config: {
            type: "select",
            options: [
              { value: "", label: "(select from drop-down)" },
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
              { value: "", label: "Not Applicable" },
            ],
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
      },
      content: [],
    },
    label: {
      main: "Section 6.1: All Other Scholarship Funding Support",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    value: [],
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
  },

  app_service_obligations: {
    elementType: "input-table",
    elementConfig: {
      header: {
        service: {
          label: "Service Obligations",
          sublabel: "(please indicate the committed in detail)",
          config: {
            type: "text",
            placeholder: "(e.g teaching assistant - 200 hours)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            isText: true,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        fulfilled: {
          label: "Fulfilled Obligations",
          sublabel: "(please indicate the obligations fulfilled)",
          config: {
            type: "text",
            placeholder: "(e.g 25 hours)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            isText: true,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        unfulfilled: {
          label: "Unfulfilled Obligations",
          sublabel: "(please indicate the obligations not fulfilled yet)",
          config: {
            type: "text",
            placeholder: "(e.g 150 hours)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            isText: true,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        expected_period: {
          label: "Expected Period of Fulfillment",
          sublabel: "(During the studies/post-studies)",
          config: {
            type: "text",
            placeholder: "(e.g During and post-studies)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            isText: true,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        pro_rated_transfer: {
          label: "Pro-rated allowed if transfer successful?",
          sublabel: "(Yes/No, pro-rated amount) ",
          config: {
            type: "text",
            placeholder: "(e.g Yes - 25 hours)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            isText: true,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
      },
      content: [],
    },
    label: {
      main: "Section 6.2: Service Obligations",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    value: [],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
  },
  // SECTION 6 - END
};

// For admin-fa.
export const app_endorse_component = {
  urop_qualifying_criteria: {
    elementType: "input-normal",
    elementConfig: {
      type: "text",
      disabled: "disabled",
    },
    label: {
      main: "AI Research Experience Qualifying Criteria",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      required: true,
    },
    value: "",
    valid: true,
    touched: false,
  },
  // NOTE: This must be the same as app_form_admin.annex_d.
  // ANNEX D - START
  annex_d: {
    elementType: "upload",
    label: "Annex D: Recommendation Letters",
    sublabel: "[max 16mb] (only accept .zip format)",
    feedback: "",
    value: "",
    valid: true,
    touched: false,
    loaded: false,
    validation: {
      // NOTE We force this to be required.
      required: true,
    },
  },
  // ANNEX D - END
  //
  // NOTE: This must be the same as app_form_admin.app_other_fundings and
  // app_form_admin.app_service_obligations.
  // SECTION 6 - START
  app_other_fundings: {
    elementType: "input-table",
    elementConfig: {
      header: {
        coverage: {
          label: "Funding Coverage",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "(please indicate the inclusion of funding)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        organisation: {
          label: "Funding Organisation",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "(max 200 characters)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        duration_of_support: {
          label: "Duration of Support (No. of Years)",
          sublabel: "",
          config: {
            type: "number",
            placeholder: "(No. of Years)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            isNumber: true,
            maxLength: 10,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        awarded_date: {
          label: "Awarded Date",
          sublabel: "",
          config: {
            type: "date",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        amount: {
          label: "Funding Amount ($S)",
          sublabel: "",
          config: {
            type: "text",
            placeholder: "(numerical only)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 10,
            isNumber: true,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        transfer: {
          label: "Allowed for Transfer of Scholarship?",
          sublabel: "",
          config: {
            type: "select",
            options: [
              { value: "", label: "(select from drop-down)" },
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
              { value: "", label: "Not Applicable" },
            ],
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
      },
      content: [],
    },
    label: {
      main: "Section 6.1: All Other Scholarship Funding Support",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    value: [],
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
  },

  app_service_obligations: {
    elementType: "input-table",
    elementConfig: {
      header: {
        service: {
          label: "Service Obligations",
          sublabel: "(please indicate the committed in detail)",
          config: {
            type: "text",
            placeholder: "(e.g teaching assistant - 200 hours)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            isText: true,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        fulfilled: {
          label: "Fulfilled Obligations",
          sublabel: "(please indicate the obligations fulfilled)",
          config: {
            type: "text",
            placeholder: "(e.g 25 hours)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            isText: true,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        unfulfilled: {
          label: "Unfulfilled Obligations",
          sublabel: "(please indicate the obligations not fulfilled yet)",
          config: {
            type: "text",
            placeholder: "(e.g 150 hours)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            isText: true,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        expected_period: {
          label: "Expected Period of Fulfillment",
          sublabel: "(During the studies/post-studies)",
          config: {
            type: "text",
            placeholder: "(e.g During and post-studies)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            isText: true,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
        pro_rated_transfer: {
          label: "Pro-rated allowed if transfer successful?",
          sublabel: "(Yes/No, pro-rated amount) ",
          config: {
            type: "text",
            placeholder: "(e.g Yes - 25 hours)",
            required: true,
          },
          value: "",
          valid: false,
          touched: false,
          validation: {
            maxLength: 200,
            isText: true,
            required: true,
          },
          feedback: {
            type: "valid",
            message: "",
          },
        },
      },
      content: [],
    },
    label: {
      main: "Section 6.2: Service Obligations",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    value: [],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
  },
  // SECTION 6 - END

  // SECTION 7 - START
  rank: {
    elementType: "input-normal",
    elementConfig: {
      type: "number",
      placeholder: "enter candidate rank (numerical only)",
    },
    label: {
      main: "Section 7.1: Rank of Candidate",
      sub: "(Rank out of number of candidate either usual candidate or unique candidate",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isNumber: true,
      maxLength: 3,
      required: true,
    },
    value: "0",
    valid: false,
    touched: false,
  },

  endorsement: {
    elementType: "upload",
    label: "Section 7.2: Endorsement Page",
    sublabel: "[max 16mb] (only accept .pdf format)",
    feedback: "",
    value: "",
    valid: false,
    touched: false,
    loaded: true,
    validation: {
      required: true,
    },
  },
  // SECTION 7 - END
};

export const app_access_applicant = {
  app_id: {
    elementType: "input-normal",
    elementConfig: {
      type: "number",
      placeholder: "(Application Number)",
    },
    label: {
      main: "Application Number",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isNumber: true,
      maxLength: 10,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },

  access_code: {
    elementType: "input-normal",
    elementConfig: {
      type: "number",
      placeholder: "(Enter your 6 digit access code)",
    },
    label: {
      main: "Access Code",
      sub: "",
    },
    feedback: {
      status: "valid",
      message: "",
    },
    validation: {
      isNumber: true,
      maxLength: 6,
      required: true,
    },
    value: "",
    valid: false,
    touched: false,
  },
};
