import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";

import SubmissionCreate from "./SubmissionCreate/SubmissionCreate";
import SubmissionForm from "./SubmissionForm/SubmissionForm";
import SubmissionView from "./SubmissionView/SubmissionView";
import SubmissionEndorse from "./SubmissionEndorse/SubmissionEndorse";

import { FormMapper } from "../../../hoc/FormConfig";
import {
  customStyle,
  locale_string,
  locale_options,
} from "../../../hoc/CustomStyle";
import axios from "axios";

class Submissions extends Component {
  state = {
    render: {
      loaded: false,
      content_type: "table", // table, form, create
      user_type: null,
    },

    table: {
      header: null,
      content: null,
      others: null,
    },

    form: {
      retrieve_id: null,
      header: null,
      content: null,
    },

    endorse: {
      retrieve_id: null,
      header: null,
      content: null,
      others: null,
    },

    view: {
      retrieve_id: null,
      header: null,
      content: null,
      status: null,
    },

    spinner: {
      toggle: false,
      msg: "",
    },

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },
  };

  constructor(props) {
    super(props);
    this.formHandler = this.formHandler.bind(this);
  }

  toggleSpinner(trigger, message) {
    this.setState({
      ...this.state,
      spinner: {
        toggle: trigger,
        msg: message,
      },
    });
  }

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  loadpageHandler(content_type, content_id) {
    this.toggleSpinner(true, "null");

    // load profile information
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      let params = {
        request_type: content_type,
        retrieve_id: content_id,
      };

      if (params) {
        // table
        axios({
          url:
            process.env.REACT_APP_AXIOS_URL +
            "fetch/applications/" +
            content_type,
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            request_type: params.request_type,
            request_id: params.retrieve_id,
          },
        })
          .then((received) => {
            //console.log(received);

            this.toggleSpinner(false, "null");

            if (received.status === 200) {
              if (received.data.status) {
                let updatedStates = {};
                if (params.request_type === "table") {
                  updatedStates = {
                    ...this.state,
                    table: {
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                      others: received.data.payload.others,
                    },
                    form: {
                      retrieve_id: null,
                      header: null,
                      content: null,
                    },
                  };
                } else if (params.request_type === "form") {
                  updatedStates = {
                    ...this.state,
                    form: {
                      ...this.state.form,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                    },
                    table: {
                      header: null,
                      content: null,
                      others: null,
                    },
                  };
                } else if (params.request_type === "endorse") {
                  if (
                    received.data.payload.others[
                      received.data.payload.content["host_institution"]
                    ]["total"]
                  ) {
                    updatedStates = {
                      ...this.state,
                      endorse: {
                        ...this.state.endorse,
                        header: received.data.payload.header,
                        content: received.data.payload.content,
                        others: received.data.payload.others,
                      },
                      table: {
                        header: null,
                        content: null,
                        others: null,
                      },
                    };
                  } else {
                    this.loadpageHandler("table", null);
                    window.confirm(
                      "Total quota exceeded, please unendorse some applications to continue."
                    );
                    return;
                  }
                } else if (params.request_type === "view") {
                  updatedStates = {
                    ...this.state,
                    view: {
                      ...this.state.view,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                      status: received.data.payload.outcome,
                    },
                  };
                }
                updatedStates = {
                  ...updatedStates,
                  render: {
                    loaded: true,
                    content_type: params.request_type,
                    user_type: this.props.grant_infor.user_type,
                  },
                };

                this.setState(updatedStates);
              } else {
                this.messageHandler("error", received.data.message);
              }
            } else {
              this.messageHandler(
                "error",
                "Unexpected error, contact site administrator if persist."
              );
            }
          })
          .catch((error) => {
            this.toggleSpinner(false, "null");

            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              this.messageHandler(
                "error",
                "An Unexpected error has occurred, please contact site admin if persists."
              );
            }
          });
      } else {
        this.toggleSpinner(false, "null");

        this.messageHandler(
          "error",
          "Invalid parameters where parameters are expected"
        );
      }
    } else {
      this.toggleSpinner(false, "null");

      this.messageHandler("expired", "Session expired, please login again");
    }

    this.setState({
      ...this.state,
      render: {
        ...this.state.render,
        loaded: false,
      },
    });
  }

  posthandler(post_type, post_id, post_mode) {
    this.toggleSpinner(true, "null");

    let accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "post/applications/" + post_type,
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          request_type: post_mode,
          request_meta: {
            grant_id: this.props.grant_infor.grant_id,
            app_id: post_id,
          },
        },
      })
        .then((received) => {
          this.toggleSpinner(false, "null");

          if (received.status === 200) {
            if (received.data.status) {
              this.loadpageHandler("table", null);
            } else {
              this.messageHandler(
                "error",
                "Unexpected error occurred, contact site admin if this persists."
              );
            }
          } else {
            this.messageHandler(
              "error",
              "Unexpected error occurred, contact site admin if it persists."
            );
          }
        })
        .catch((error) => {
          this.toggleSpinner(false, "null");

          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.messageHandler(
              "error",
              "An Unexpected error has occurred, please contact site admin if persists."
            );
          }
        });
    } else {
      this.toggleSpinner(false, "null");
      this.messageHandler("expired", "Session expired. Please log in again");
    }
  }

  downloadHandler(event, app_id) {
    //console.log(event)
    const FileDownload = require("js-file-download");
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      if (event === "download") {
        axios({
          url: process.env.REACT_APP_AXIOS_URL + "file/admin/download/single",
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            request_id: app_id,
          },
          responseType: "arraybuffer",
        }).then((received) => {
          FileDownload(received.data, "app_" + app_id + ".zip");
        });
      } else if (event === "all") {
        axios({
          url: process.env.REACT_APP_AXIOS_URL + "file/admin/download/all",
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            request_id: null,
          },
          responseType: "arraybuffer",
        }).then((received) => {
          FileDownload(received.data, "applications_all.zip");
        });
      } else {
        axios({
          url: process.env.REACT_APP_AXIOS_URL + "file/admin/download/summary",
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            request_id: app_id,
          },
          responseType: "arraybuffer",
        }).then((received) => {
          FileDownload(received.data, "app_" + app_id + ".pdf");
        });
      }
    }
  }

  viewHandler(event, submission_id) {
    this.loadpageHandler("view", submission_id);
  }

  modifyHandler(event, submission_id) {
    this.loadpageHandler("form", submission_id);
  }

  deleteHandler(event, submission_id) {
    this.posthandler("delete", submission_id, null);
  }

  createHandler(event) {
    let updatedStates = {
      ...this.state,
      render: {
        loaded: true,
        content_type: "create",
        user_type: this.props.grant_infor.user_type,
      },
    };
    this.setState(updatedStates);
  }

  formHandler() {
    this.loadpageHandler("table", null);
  }

  selectHandler(event, submission_id) {
    if (event.target.value === "edit") {
      // both super admin and cluster admin
      this.loadpageHandler("form", submission_id);
    } else if (event.target.value === "reject") {
      let response = window.confirm(
        "Are you sure if this appliaction is to be rejected?"
      );
      if (response) {
        this.posthandler("table", submission_id, "rejected"); // au admin and super
      }
    } else if (event.target.value === "unendorse") {
      // Relies on special error message to yell at user that they cannot
      // unendorse when they don't have sufficient ASEAN candidates left.
      let response = window.confirm(
        "Are you sure if this application is to be unendorsed?"
      );
      if (response) {
        this.posthandler("table", submission_id, "unendorse"); // super admin only <-- That's a lie.
      }
    } else if (event.target.value === "awarded") {
      this.posthandler("table", submission_id, "awarded"); // super admin only
    } else if (event.target.value === "conditional_award") {
      this.posthandler("table", submission_id, "conditional_award"); // super admin only
    } else if (event.target.value === "withdrawn") {
      this.posthandler("table", submission_id, "withdrawn"); // super admin only
    } else if (event.target.value === "delete") {
      var response = window.confirm(
        "Are you sure if this application is to be deleted?"
      );
      if (response === true) {
        this.posthandler("table", submission_id, "delete"); // au admin only
      }
    } else if (event.target.value === "pending") {
      this.posthandler("table", submission_id, "pending"); // super admin only
    } else if (event.target.value === "endorse") {
      this.loadpageHandler("endorse", submission_id); // for au admin only (FIXME Should be for FA)
    }
  }

  componentDidMount() {
    this.loadpageHandler("table", null);
  }

  sortByOrder(obj) {
    const object_array = Object.entries(obj).sort(
      (a, b) => a[1].order - b[1].order
    );
    const output_object = {};

    for (var i = 0; i < object_array.length; i++) {
      output_object[object_array[i][0]] = object_array[i][1];
    }
    return output_object;
  }

  render() {
    let header = <b>Submissions</b>;
    let content = <p>Loading ...</p>;

    // This is used to block actions other than viewing when the deadline is
    // hit.
    let deadline = new Date(this.props.grant_infor.closing_date);

    // message handling
    let msg = null;
    if (this.state.internalMsg.triggered) {
      if (this.state.internalMsg.type === "success") {
        msg = (
          <p style={customStyle.successMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      } else if (this.state.internalMsg.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      }
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    // load display
    if (this.state.render.loaded) {
      if (this.state.render.content_type === "table") {
        let quota_board = null;
        if (this.props.grant_infor.user_type === "admin-fa") {
          let total_endorse =
            this.state.table.others[this.state.table.others["affiliation"]][
              "total_endorse"
            ];
          let total_max =
            this.state.table.others[this.state.table.others["affiliation"]][
              "total_max"
            ];

          quota_board = (
            <div>
              <Row>
                <Col sm={2}>
                  <b>Quota:</b>
                </Col>
                <Col sm={9}>
                  <Badge variant="info">
                    Total: {total_endorse}/{total_max}
                  </Badge>
                </Col>
              </Row>
              <hr></hr>
            </div>
          );
        }

        const ordered_headers = this.sortByOrder(this.state.table.header);

        let num_columns = 0;
        const table_header = Object.entries(ordered_headers).map(
          ([key, entry], idx) => {
            num_columns++;
            return <th key={key}>{entry.name}</th>;
          }
        );

        let table_content = (
          <tr>
            <td colSpan={num_columns}>No applications found ...</td>
          </tr>
        );
        if (this.state.table.content.length > 0) {
          table_content = this.state.table.content.map((element, idx) => {
            let action_item = <p>N/A</p>;
            let app_name = <p>N/A</p>;

            for (const key in ordered_headers) {
              // generate action button
              if (key === "action") {
                if (this.props.grant_infor.user_type === "admin-super") {
                  action_item = (
                    <>
                      <Form.Control
                        as="select"
                        value=""
                        onChange={(event) =>
                          this.selectHandler(event, element["app_id"])
                        }
                      >
                        <option value="">(select)</option>
                        <option value="edit">Edit</option>
                        <option value="awarded">Award</option>
                        <option value="conditional_award">
                          Conditional Offer
                        </option>
                        <option value="endorse">Endorse</option>
                        {/*<option value="unendorse">Unendorse</option>*/}
                        <option value="reject">Reject</option>
                        <option value="withdrawn">Withdrawn</option>
                        {/* <option value='delete'>Delete</option> */}
                      </Form.Control>
                    </>
                  );
                } else {
                  // Either FA or AU.  But in this case, FA == AU.
                  if (
                    this.props.grant_infor.grant_status === "open" &&
                    Date.now() < deadline
                  ) {
                    switch (element["status"]) {
                      case "complete-un":
                        if (this.props.grant_infor.user_type === "admin-fa") {
                          if (this.state.table.others["edit"] === false) {
                            action_item = (
                              <>
                                <Form.Control
                                  as="select"
                                  value=""
                                  onChange={(event) =>
                                    this.selectHandler(event, element["app_id"])
                                  }
                                >
                                  <option value="">(select)</option>
                                </Form.Control>
                              </>
                            );
                          } else {
                            action_item = (
                              <>
                                <Form.Control
                                  as="select"
                                  value=""
                                  onChange={(event) =>
                                    this.selectHandler(event, element["app_id"])
                                  }
                                >
                                  <option value="">(select)</option>
                                  <option value="edit">Edit</option>
                                  <option value="endorse">Endorse</option>
                                  <option value="reject">Reject</option>
                                </Form.Control>
                              </>
                            );
                          }
                        }
                        break;
                      case "complete-en":
                        if (this.props.grant_infor.user_type === "admin-fa") {
                          if (this.state.table.others["edit"] === false) {
                            action_item = (
                              <>
                                <Form.Control
                                  as="select"
                                  value=""
                                  onChange={(event) =>
                                    this.selectHandler(event, element["app_id"])
                                  }
                                >
                                  <option value="">(select)</option>
                                </Form.Control>
                              </>
                            );
                          } else {
                            action_item = (
                              <>
                                <Form.Control
                                  as="select"
                                  value=""
                                  onChange={(event) =>
                                    this.selectHandler(event, element["app_id"])
                                  }
                                >
                                  <option value="">(select)</option>
                                  <option value="unendorse">Unendorse</option>
                                  <option value="reject">Reject</option>
                                </Form.Control>
                              </>
                            );
                          }
                        }
                        break;
                      case "reject":
                        action_item = (
                          <>
                            <Form.Control
                              as="select"
                              value=""
                              onChange={(event) =>
                                this.selectHandler(event, element["app_id"])
                              }
                            >
                              <option value="">(select)</option>
                            </Form.Control>
                          </>
                        );
                        break;
                      case "unfilled":
                        if (this.props.grant_infor.user_type === "admin-fa") {
                          action_item = (
                            <>
                              <Form.Control
                                as="select"
                                value=""
                                onChange={(event) =>
                                  this.selectHandler(event, element["app_id"])
                                }
                              >
                                <option value="">(select)</option>
                                <option value="edit">Edit</option>
                                <option value="delete">Delete</option>
                              </Form.Control>
                            </>
                          );
                        }
                        break;
                      case "filled":
                        if (this.props.grant_infor.user_type === "admin-fa") {
                          action_item = (
                            <>
                              <Form.Control
                                as="select"
                                value=""
                                onChange={(event) =>
                                  this.selectHandler(event, element["app_id"])
                                }
                              >
                                <option value="">(select)</option>
                                <option value="edit">Edit</option>
                                <option value="endorse">Endorse</option>
                                <option value="delete">Delete</option>
                              </Form.Control>
                            </>
                          );
                        }
                        break;
                      case "pending-ass":
                        action_item = (
                          <>
                            <Form.Control
                              as="select"
                              value=""
                              onChange={(event) =>
                                this.selectHandler(event, element["app_id"])
                              }
                            >
                              <option value="">(select)</option>
                            </Form.Control>
                          </>
                        );
                        break;
                      case "complete":
                        action_item = (
                          <>
                            <Form.Control
                              as="select"
                              value=""
                              onChange={(event) =>
                                this.selectHandler(event, element["app_id"])
                              }
                            >
                              <option value="">(select)</option>
                            </Form.Control>
                          </>
                        );
                        break;
                      default:
                        action_item = (
                          <>
                            <Form.Control
                              as="select"
                              value=""
                              onChange={(event) =>
                                this.selectHandler(event, element["app_id"])
                              }
                            >
                              <option value="">(select)</option>
                              <option value="edit">Edit</option>
                              <option value="delete">Delete</option>
                            </Form.Control>
                          </>
                        );
                        break;
                    }
                  } else {
                    switch (element["status"]) {
                      case "complete-en":
                        action_item = <>No action available.</>;
                        break;
                      default:
                        action_item = (
                          <>
                            Deadline to submit by:{" "}
                            {deadline.toLocaleString(
                              locale_string,
                              locale_options
                            )}
                          </>
                        );
                        break;
                    }
                  }
                }
              } else if (key === "app_name") {
                app_name = (
                  <span
                    className="btn-link"
                    onClick={(event) =>
                      this.viewHandler(event, element["app_id"])
                    }
                  >
                    {element["app_name"]}
                  </span>
                );
              }
            }

            const rowElement = Object.entries(ordered_headers).map(
              ([key, entry], row_idx) => {
                if (key === "action") {
                  return <td key={row_idx}>{action_item}</td>;
                } else if (key === "app_name") {
                  return <td key={row_idx}>{app_name}</td>;
                } else {
                  return (
                    <td key={row_idx}>
                      {FormMapper[element[key]]
                        ? FormMapper[element[key]]
                        : element[key]}
                    </td>
                  );
                }
              }
            );

            return <tr key={idx}>{rowElement}</tr>;
          });
        }

        if (
          this.state.render.user_type === "admin-fa" &&
          this.props.grant_infor.grant_status === "open" &&
          Date.now() < deadline
        ) {
          header = (
            <>
              <Row>
                <Col sm={10}>
                  Create a new application? Click the button on the right.
                </Col>
                <Col sm={2}>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={(event) => this.createHandler(event)}
                  >
                    Create
                  </Button>
                </Col>
              </Row>
            </>
          );
        }

        let download = null;
        if (
          this.state.render.user_type === "admin-au" ||
          this.state.render.user_type === "admin-super" ||
          this.state.render.user_type === "admin-fa"
        ) {
          download = (
            <div>
              <Button
                variant="info"
                onClick={() => this.downloadHandler("all", null)}
              >
                Download all
              </Button>
              <p></p>
            </div>
          );
        }

        content = (
          <>
            {quota_board}
            {download}
            <Table striped bordered responsive>
              <thead>
                <tr>{table_header}</tr>
              </thead>
              <tbody>{table_content}</tbody>
            </Table>
          </>
        );
      } else if (this.state.render.content_type === "create") {
        header = <b>Create a new Application</b>;
        content = (
          <SubmissionCreate
            formHandler={this.formHandler}
            grant_infor={this.props.grant_infor}
          />
        );
      } else if (this.state.render.content_type === "form") {
        header = (
          <b>Edit Application with Id: {this.state.form.content["app_id"]}</b>
        );
        content = (
          <SubmissionForm
            sub_infor={this.state.form}
            formHandler={this.formHandler}
            grant_infor={this.props.grant_infor}
          />
        );
      } else if (this.state.render.content_type === "endorse") {
        header = (
          <b>
            Endorse Application with Id: {this.state.endorse.content["app_id"]}
          </b>
        );
        content = (
          <SubmissionEndorse
            sub_infor={this.state.endorse}
            formHandler={this.formHandler}
            grant_infor={this.props.grant_infor}
          />
        );
      } else if (this.state.render.content_type === "view") {
        header = <b>Application Summary</b>;
        content = (
          <SubmissionView
            formHandler={this.formHandler}
            downloadHandler={this.downloadHandler}
            grant_infor={this.props.grant_infor}
            sub_infor={this.state.view}
          />
        );
      }
    }

    return (
      <>
        {msg}
        <div style={customStyle.topBuffer20}>
          <Card>
            <Card.Header>{header}</Card.Header>
            <Card.Body>
              {this.state.spinner.toggle ? (
                <>
                  <Spinner animation="border" size="sm" /> Loading, please do
                  not refresh your page{" "}
                </>
              ) : null}
              {content}
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

export default Submissions;
