import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import ReviewForm from "./ReviewForm/ReviewForm";
import ReviewView from "./ReviewView/ReviewView";
import ReviewCreate from "./ReviewCreate/ReviewCreate";
import Spinner from "react-bootstrap/Spinner";

import { FormMapper } from "../../../hoc/FormConfig";
import { customStyle } from "../../../hoc/CustomStyle";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faCaretUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";

// TODO Add a button to magically assign all reviewers to all endorsed
// applications.

class Reviews extends Component {
  state = {
    render: {
      loaded: false,
      content_type: "table", // table, form, editorboard
      user_type: null,
    },

    table: {
      header: null,
      content: null,
    },

    form: {
      retrieve_id: null,
      header: null,
      content: null,
    },

    view: {
      retrieve_id: null,
      header: null,
      content: null,
      status: null,
    },

    create: {
      retrieve_id: null,
      header: null,
      content: null,
      status: null,
    },

    spinner: {
      toggle: false,
      msg: "",
    },

    internalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    externalMsg: {
      triggered: false,
      type: "",
      content: "",
    },

    selected: {
      app_id: faCaretUp,
      app_name: faSort,
      affiliation: faSort,
      type: faSort,
      citizenship: faSort,
      score: faSort,
      outcome: faSort,
      status: faSort,
    },
  };

  constructor(props) {
    super(props);
    this.formHandler = this.formHandler.bind(this);
  }

  // FIXME Is this... supposed to be taken out?
  //formHandler() {
  //  this.loadpageHandler("table", null, null);
  //}

  toggleSpinner(trigger, message) {
    this.setState({
      ...this.state,
      spinner: {
        toggle: trigger,
        msg: message,
      },
    });
  }

  messageHandler(msg_type, msg_content) {
    if (msg_type === "expired") {
      this.setState({
        ...this.state,
        externalMsg: {
          triggered: true,
          type: "error",
          content: msg_content,
        },
      });
    } else {
      this.setState({
        ...this.state,
        internalMsg: {
          triggered: true,
          type: msg_type,
          content: msg_content,
        },
      });
    }
  }

  loadpageHandler(content_type, content_id, reviewer_id) {
    this.toggleSpinner(true, "null");

    // load profile information
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      let params = {
        request_type: content_type,
        retrieve_id: content_id,
        reviewer_id: reviewer_id,
      };

      if (params) {
        // table
        axios({
          url: process.env.REACT_APP_AXIOS_URL + "fetch/review/" + content_type,
          method: "post",
          auth: {
            username: accessToken,
            password: "unused",
          },
          data: {
            request_type: params.request_type,
            request_id: params.retrieve_id,
            reviewer_id: reviewer_id,
          },
        })
          .then((received) => {
            this.toggleSpinner(false, "null");

            if (received.status === 200) {
              if (received.data.status) {
                let updatedStates = {};
                if (params.request_type === "table") {
                  updatedStates = {
                    ...this.state,
                    table: {
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                      others: received.data.payload.others,
                    },
                    form: {
                      retrieve_id: null,
                      header: null,
                      content: null,
                    },
                  };
                } else if (params.request_type === "form") {
                  updatedStates = {
                    ...this.state,
                    form: {
                      ...this.state.form,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                    },
                    table: {
                      header: null,
                      content: null,
                      others: null,
                    },
                  };
                } else if (params.request_type === "view") {
                  updatedStates = {
                    ...this.state,
                    view: {
                      ...this.state.view,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                      status: received.data.payload.outcome,
                      reviewer: reviewer_id,
                    },
                  };
                } else if (params.request_type === "create") {
                  updatedStates = {
                    ...this.state,
                    create: {
                      ...this.state.create,
                      header: received.data.payload.header,
                      content: received.data.payload.content,
                      status: received.data.payload.outcome,
                    },
                  };
                }

                updatedStates = {
                  ...updatedStates,
                  render: {
                    loaded: true,
                    content_type: params.request_type,
                    user_type: this.props.grant_infor.user_type,
                  },
                };

                this.setState(updatedStates);
              } else {
                this.messageHandler("error", received.data.message);
              }
            } else {
              this.messageHandler(
                "error",
                "Unexpected error, contact site administrator if persist."
              );
            }
          })
          .catch((error) => {
            this.toggleSpinner(false, "null");

            if (error.response && error.response.status === 401) {
              this.messageHandler(
                "expired",
                "Session expired. Please log in again"
              );
            } else {
              this.messageHandler(
                "error",
                "An Unexpected error has occurred, please contact site admin if persists."
              );
            }
          });
      } else {
        this.toggleSpinner(false, "null");

        this.messageHandler(
          "error",
          "Invalid parameters where parameters are expected"
        );
      }
    } else {
      this.toggleSpinner(false, "null");

      this.messageHandler("expired", "Session expired, please login again");
    }

    this.setState({
      ...this.state,
      render: {
        ...this.state.render,
        loaded: false,
      },
    });
  }

  resetHandler() {
    this.toggleSpinner(true, "null");

    let accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "post/review/reset",
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
      })
        .then((received) => {
          this.toggleSpinner(false, "null");

          if (received.status === 200) {
            if (received.data.status) {
            } else {
              this.messageHandler("error", received.data.message);
            }
          } else {
            this.messageHandler(
              "error",
              "Unexpected error occurred, contact site admin if persist"
            );
          }
        })
        .catch((error) => {
          this.toggleSpinner(false, "null");

          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.messageHandler(
              "error",
              "An Unexpected error has occurred, please contact site admin if persists."
            );
          }
        });
    } else {
      this.toggleSpinner(false, "null");
      this.messageHandler("expired", "Session expired. Please log in again");
    }
  }

  posthandler(post_type, post_id, post_mode) {
    this.toggleSpinner(true, "null");

    let accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      axios({
        url: process.env.REACT_APP_AXIOS_URL + "post/review/" + post_type,
        method: "post",
        auth: {
          username: accessToken,
          password: "unused",
        },
        data: {
          request_type: post_mode,
          request_meta: {
            grant_id: this.props.grant_infor.grant_id,
            app_id: post_id,
          },
        },
      })
        .then((received) => {
          this.toggleSpinner(false, "null");

          if (received.status === 200) {
            if (received.data.status) {
              this.loadpageHandler("table", null, null);
            } else {
              this.messageHandler("error", received.data.message);
            }
          } else {
            this.messageHandler(
              "error",
              "Unexpected error occurred, contact site admin if persist"
            );
          }
        })
        .catch((error) => {
          this.toggleSpinner(false, "null");

          if (error.response && error.response.status === 401) {
            this.messageHandler(
              "expired",
              "Session expired. Please log in again"
            );
          } else {
            this.messageHandler(
              "error",
              "An Unexpected error has occurred, please contact site admin if persists."
            );
          }
        });
    } else {
      this.toggleSpinner(false, "null");
      this.messageHandler("expired", "Session expired. Please log in again");
    }
  }

  viewHandler(event, submission_id, reviwer_id) {
    this.loadpageHandler("view", submission_id, reviwer_id);
  }

  formHandler(column, order) {
    this.loadpageHandler("table", column, order);
  }

  downloadHandler(event, app_id, reviewer_id) {
    const FileDownload = require("js-file-download");
    let accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      switch (event) {
        case "download":
          axios({
            url: process.env.REACT_APP_AXIOS_URL + "file/admin/download/single",
            method: "post",
            auth: {
              username: accessToken,
              password: "unused",
            },
            data: {
              request_id: app_id,
            },
            responseType: "arraybuffer",
          }).then((received) => {
            FileDownload(received.data, "app_" + app_id + ".zip");
          });
          break;
        case "all":
          axios({
            url: process.env.REACT_APP_AXIOS_URL + "file/admin/download/all",
            method: "post",
            auth: {
              username: accessToken,
              password: "unused",
            },
            data: {
              request_id: app_id,
            },
            responseType: "arraybuffer",
          }).then((received) => {
            FileDownload(received.data, "applications_all.zip");
          });
          break;
        case "review":
          axios({
            url: process.env.REACT_APP_AXIOS_URL + "file/admin/download/review",
            method: "post",
            auth: {
              username: accessToken,
              password: "unused",
            },
            data: {
              request_id: app_id,
              reviewer_id: reviewer_id,
            },
            responseType: "arraybuffer",
          }).then((received) => {
            FileDownload(received.data, "review_" + app_id + ".pdf");
          });
          break;
        case "delete":
          axios({
            url: process.env.REACT_APP_AXIOS_URL + "post/review/delete",
            method: "post",
            auth: {
              username: accessToken,
              password: "unused",
            },
            data: {
              request_id: app_id,
              reviewer_id: reviewer_id,
            },
          }).then((received) => {
            this.formHandler(null, null);
          });
          break;
        default:
      }
    }
  }

  selectHandler(event, submission_id, reviewer_id) {
    if (event.target.value === "edit") {
      // both super admin and cluster admin
      this.loadpageHandler("edit", submission_id, null);
    } else if (event.target.value === "reject") {
      let response = window.confirm(
        "Are you sure if this application is to be rejected?"
      );
      if (response) this.posthandler("table", submission_id, "rejected"); // au admin only
    } else if (event.target.value === "awarded") {
      this.posthandler("table", submission_id, "awarded"); // super admin only
    } else if (event.target.value === "delete") {
      let response = window.confirm(
        "Are you sure if this application is to be deleted?"
      );
      if (response) this.downloadHandler("delete", submission_id, reviewer_id); // super admin only
    } else if (event.target.value === "pending") {
      this.posthandler("table", submission_id, "pending"); // super admin only
    } else if (event.target.value === "form") {
      this.loadpageHandler("form", submission_id, null); // for reviewer and super admin
    } else if (event.target.value === "assign") {
      this.loadpageHandler("create", submission_id, null); // for reviewer and super admin
    }
  }

  componentDidMount() {
    this.loadpageHandler("table", null, null);
  }

  sortByOrder(obj) {
    const object_array = Object.entries(obj).sort(
      (a, b) => a[1].order - b[1].order
    );
    const output_object = {};

    for (var i = 0; i < object_array.length; i++) {
      output_object[object_array[i][0]] = object_array[i][1];
    }
    return output_object;
  }

  changeIcon(key) {
    // FIXME Rewrite to use useState() instead.
    if (this.state.selected[key] === faSort) {
      //Selected some key other than the previously selected
      Object.keys(this.state.selected).forEach((_key, index) => {
        if (this.state.selected[_key] !== faSort)
          this.state.selected[_key] = faSort;
      });
      this.resetHandler();
      this.state.selected[key] = faCaretDown;
    } else if (this.state.selected[key] === faCaretUp)
      this.state.selected[key] = faCaretDown;
    else this.state.selected[key] = faCaretUp;
  }

  render() {
    let header = <b>Reviews</b>;
    let content = <p>Loading ...</p>;

    // message handling
    let msg = null;
    if (this.state.internalMsg.triggered) {
      if (this.state.internalMsg.type === "success") {
        msg = (
          <p style={customStyle.successMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      } else if (this.state.internalMsg.type === "error") {
        msg = (
          <p style={customStyle.errorMessage}>
            {this.state.internalMsg.content}
          </p>
        );
      }
    }

    // redirect to login page if there is any error
    if (
      this.state.externalMsg.triggered &&
      this.state.externalMsg.type === "error"
    ) {
      localStorage.clear("access_token");
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              message: {
                type: "error",
                content: this.state.externalMsg.content,
              },
            },
          }}
        />
      );
    }

    // load display
    if (this.state.render.loaded) {
      if (this.state.render.content_type === "table") {
        const ordered_headers = this.sortByOrder(this.state.table.header);

        let num_columns = 0;
        const table_header = Object.entries(ordered_headers).map(
          ([key, entry], idx) => {
            num_columns++;
            if (key === "action" || key === "reviewer") {
              return <th key={key}>{entry.name}</th>;
            } else {
              return (
                <th key={key}>
                  {entry.name}
                  <FontAwesomeIcon
                    icon={this.state.selected[key]}
                    variant="link"
                    onClick={(event) => {
                      this.changeIcon(key);
                      this.formHandler(key);
                    }}
                  />
                </th>
              );
            }
          }
        );

        let table_content = (
          <tr>
            <td colSpan={num_columns}>No applications found ...</td>
          </tr>
        );
        if (this.state.table.content.length > 0) {
          table_content = this.state.table.content.map((element, idx) => {
            let action_item = <p>N/A</p>;
            let app_name = <p>N/A</p>;
            let reviewers_name = <p>N/A</p>;

            for (const key in ordered_headers) {
              // generate action button
              if (key === "action") {
                // if (element['reviewer'] === ''){

                // }
                // else{
                if (this.props.grant_infor.user_type === "admin-super") {
                  if (element["reviewer"] === "") {
                    action_item = (
                      <>
                        <Form.Control
                          as="select"
                          value=""
                          onChange={(event) =>
                            this.selectHandler(event, element["app_id"])
                          }
                        >
                          <option value="">(select)</option>
                          {/* This makes no sense, and it causes a crash.
                          <option value="form">Review</option>
                          */}
                          <option value="assign">Assign</option>
                        </Form.Control>
                      </>
                    );
                    app_name = element["app_name"];
                    reviewers_name = "N/A";
                  } else {
                    action_item = (
                      <>
                        <Form.Control
                          as="select"
                          value=""
                          onChange={(event) =>
                            this.selectHandler(
                              event,
                              element["app_id"],
                              element["reviewer"][0].split(" ")[0]
                            )
                          }
                        >
                          <option value="">(select)</option>
                          <option value="assign">Assign</option>
                        </Form.Control>
                      </>
                    );
                    app_name = element["app_name"];

                    reviewers_name = Object.entries(element["reviewer"]).map(
                      (value, index) => {
                        return (
                          <tr>
                            <Button
                              variant="link"
                              onClick={(event) =>
                                this.viewHandler(
                                  event,
                                  element["app_id"],
                                  element["reviewer"][index]
                                )
                              }
                            >
                              {element["reviewer"][index]}
                            </Button>
                          </tr>
                        );
                      }
                    );
                  }
                } else {
                  if (element["status"] !== "Submitted") {
                    action_item = (
                      <>
                        <Form.Control
                          as="select"
                          value=""
                          onChange={(event) =>
                            this.selectHandler(event, element["app_id"])
                          }
                        >
                          <option value="">(select)</option>
                          <option value="form">Review</option>
                        </Form.Control>
                      </>
                    );
                  } else {
                    action_item = <>No actions available.</>;
                  }
                  app_name = (
                    <span
                      className="btn-link"
                      onClick={(event) =>
                        this.viewHandler(event, element["app_id"], null)
                      }
                    >
                      {element["app_name"]}
                    </span>
                  );
                }
              }
              //  }
            }

            const rowElement = Object.entries(ordered_headers).map(
              ([key, entry], row_idx) => {
                if (key === "action") {
                  return <td key={row_idx}>{action_item}</td>;
                } else if (key === "app_name") {
                  return <td key={row_idx}>{app_name}</td>;
                } else if (
                  key === "reviewer" &&
                  this.props.grant_infor.user_type === "admin-super"
                ) {
                  return <td key={row_idx}>{reviewers_name}</td>;
                } else {
                  return (
                    <td key={row_idx}>
                      {FormMapper[element[key]]
                        ? FormMapper[element[key]]
                        : element[key]}
                    </td>
                  );
                }
              }
            );

            return <tr key={idx}>{rowElement}</tr>;
          });
        }

        let download = (
          <Button
            variant="info"
            onClick={() => this.downloadHandler("all", null)}
          >
            Download all
          </Button>
        );

        let assign_all = "";

        if (this.props.grant_infor.user_type === "admin-super") {
          assign_all = (
            <Button
              variant="warning"
              onClick={() => {
                var response = window.confirm(
                  "Assign all applications to all reviewers?"
                );
                if (response) {
                  this.posthandler("assign_all", null, null);
                  this.loadpageHandler("table", null, null);
                }
              }}
            >
              Assign all
            </Button>
          );
        }

        content = (
          <>
            <div>
              {download} {assign_all}
              <p></p>
            </div>
            <Table striped bordered responsive>
              <thead>
                <tr>{table_header}</tr>
              </thead>
              <tbody>{table_content}</tbody>
            </Table>
          </>
        );
      } else if (this.state.render.content_type === "create") {
        header = <b>Assign reviewer for this application</b>;
        content = (
          <ReviewCreate
            formHandler={this.formHandler}
            sub_infor={this.state.create}
          />
        );
      } else if (this.state.render.content_type === "form") {
        header = (
          <b>Review Application with Id: {this.state.form.content["app_id"]}</b>
        );
        content = (
          <ReviewForm
            sub_infor={this.state.form}
            formHandler={this.formHandler}
            grant_infor={this.props.grant_infor}
            downloadHandler={this.downloadHandler}
          />
        );
      } else if (this.state.render.content_type === "view") {
        header = <b>Review Summary</b>;
        content = (
          <ReviewView
            formHandler={this.formHandler}
            downloadHandler={this.downloadHandler}
            grant_infor={this.props.grant_infor}
            sub_infor={this.state.view}
          />
        );
      }
    }

    return (
      <>
        {msg}
        <div style={customStyle.topBuffer20}>
          <Card>
            <Card.Header>{header}</Card.Header>
            <Card.Body>
              {this.state.spinner.toggle ? (
                <>
                  <Spinner animation="border" size="sm" /> Loading, please do
                  not refresh your page{" "}
                </>
              ) : null}
              {content}
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

export default Reviews;
